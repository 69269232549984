import './App.css';
import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import {
  AppstoreOutlined,
  BarChartOutlined,
  CloudOutlined,
  ShopOutlined,
  TeamOutlined,
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
  MinusCircleOutlined,
  PlusOutlined
} from '@ant-design/icons';
import type { MenuProps, GetProps, GetProp } from 'antd';
import { Layout, Menu, theme, Input, Button, Form, Result, Spin, Checkbox, Col, Row, Select } from 'antd';

const { Header, Content, Footer, Sider } = Layout;
const { Search } = Input;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};

interface PostData {
  // 定义发送数据的结构，根据实际需要进行调整
  action: any;
  status: any;
  question?: any | undefined;
  plan?: any | undefined;
  id?: any;
  query?: any;
  topic?: any;
  duration?: any
}

const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 20, offset: 4 },
  },
};


const siderStyle: React.CSSProperties = {
  overflow: 'auto',
  height: '100vh',
  position: 'fixed',
  insetInlineStart: 0,
  top: 0,
  bottom: 0,
  scrollbarWidth: 'thin',
  scrollbarColor: 'unset',
};

const items: MenuProps['items'] = [
  {key: '1', icon: React.createElement(UserOutlined), label: '数据标注'},
  {key: '2', icon: React.createElement(TeamOutlined), label: '数据审查'},
]


type SearchProps = GetProps<typeof Input.Search>;
const onSearch: SearchProps['onSearch'] = (value, _e, info) => console.log(info?.source, value);


const App: React.FC = () => {
  const [question, setQuestion] = useState<string>('');
  const [plan, setPlan] = useState<string>('');
  const [status, setStatus] = useState<any>(0)
  const formRef = useRef<any>(null);
  const [selectedKeys, setSelectedKeys] = useState(['1']);
  const [loading, setLoading] = useState<boolean>(true);
  const [id, setId] = useState<any>(null);
  const [duration, setDuration] = useState<any>(2);
  const [topic, setTopic] = useState<any>(['经典游澳']);

  const handleChange = (value: number) => {
  console.log(`selected ${value}`);
    setDuration(value);
  };

  const onChange: GetProp<typeof Checkbox.Group, 'onChange'> = (checkedValues) => {
    console.log('checked = ', checkedValues);
    setTopic(checkedValues);
  };


  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const onFinish = (values: any) => {
    setLoading(true);
    const fetchData = async () => {
      const postData: PostData = {
        action: 1,
        status: status,
        id: id,
        question: values['userQuestion'],
        plan: values['travelPlan'],
        query: values['names'],
        topic: topic,
        duration: duration
      };
      console.log(postData)

      try {
        const response = await axios.post('https://utran.site/gunicorn/label', postData);
        const data = response.data.result;
        // console.log(data);
        setId(data['id']);
        setQuestion(data['question']);
        setPlan(data['plan']);
        setStatus(data['status']);
        setDuration(data['duration']);
        setTopic(data['topic']);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          console.error('Axios error:', error.response?.data);
        } else {
          console.error('Unexpected error:', error);
        }
      }
    };

    fetchData();
    setLoading(false);
  };

  const handleClick = (e: any) => {
    setSelectedKeys([e.key]); // 更新选中的菜单项
  };

  useEffect(() => {
    const fetchData = async () => {
      const postData: PostData = {
        action: 0,
        status: 0 ,
      };

      try {
        const response = await axios.post('https://utran.site/gunicorn/label', postData);
        const data = response.data.result;
        // console.log(data);
        setId(data['id']);
        setQuestion(data['question']);
        setPlan(data['plan']);
        setStatus(data['status']);
        setDuration(data['duration']);
        setTopic(data['topic']);
      } catch (error) {
        if (axios.isAxiosError(error)) {
          console.error('Axios error:', error.response?.data);
        } else {
          console.error('Unexpected error:', error);
        }
      }
    };

    fetchData();

    const handleCopy = (event: ClipboardEvent) => {
      // 检查触发复制事件的元素
      const target = event.target as HTMLElement;
      const isInput = target.tagName === 'INPUT' || target.tagName === 'TEXTAREA';
      if (isInput) {
        // 阻止文本框和输入框的复制操作
        event.preventDefault();
      }
    };

    // 添加事件监听器
    document.addEventListener('copy', handleCopy);

    // 组件卸载时移除事件监听器
    return () => {
      document.removeEventListener('copy', handleCopy);
    };
    setLoading(false);
  }, []);

  useEffect(() => {
    // 使用 setFieldsValue 更新表单域的值
    formRef.current!.setFieldsValue({
      userQuestion: question,
      travelPlan: plan,
      topic: topic,
      duration: duration
    });
  }, [id]);

  useEffect(() => {
    setLoading(true);
    if (selectedKeys[0] === '1') {
      const fetchData = async () => {
        const postData: PostData = {
          action: 0,
          status: 0,
        };

        try {
          const response = await axios.post('https://utran.site/gunicorn/label', postData);
          const data = response.data.result;
          // console.log(data)
          setId(data['id']);
          setQuestion(data['question']);
          setPlan(data['plan']);
          setStatus(data['status']);
          setDuration(data['duration']);
          setTopic(data['topic']);
        } catch (error) {
          if (axios.isAxiosError(error)) {
            console.error('Axios error:', error.response?.data);
          } else {
            console.error('Unexpected error:', error);
          }
        }
      };

      fetchData();
    } else {
      const fetchData = async () => {
        const postData: PostData = {
          action: 0,
          status: 1,
        };

        try {
          const response = await axios.post('https://utran.site/gunicorn/label', postData);
          const data = response.data.result;
          if (data === null) {
            setStatus(-1);
          } else {
            setId(data['id']);
            setQuestion(data['question']);
            setPlan(data['plan']);
            setStatus(data['status']);
            setDuration(data['duration']);
            setTopic(data['topic']);
          }
        } catch (error) {
          if (axios.isAxiosError(error)) {
            console.error('Axios error:', error.response?.data);
          } else {
            console.error('Unexpected error:', error);
          }
        }
      };

      fetchData();
    }
    setLoading(false);
  }, [selectedKeys]);

  return (
    <Layout hasSider>
      <Sider style={siderStyle}>
        <div className="demo-logo-vertical">
          <img src='./uti-logo-white.png' alt="Logo" width="120" style={{ width: "95%", marginTop: "20px", marginBottom: "20px" }} />
        </div>
        <Menu theme="dark" selectedKeys={selectedKeys} onClick={handleClick} mode="inline" defaultSelectedKeys={['1']} items={items} />
      </Sider>
      <Layout style={{ marginInlineStart: 200 }}>
        <Header style={{ padding: 0, background: colorBgContainer }} />
        <Content style={{ margin: '24px 16px 0', overflow: 'initial' }}>
          {
            status === -1 ? (
                <Result
                  title="没有需要检查的数据啦！"
                />
            ) : (
                <Spin tip="Loading" size="large" spinning={loading}>
                    <div
                    style={{
                      padding: 24,
                      textAlign: 'center',
                      background: colorBgContainer,
                      borderRadius: borderRadiusLG,
                    }}
                  >
                    <Form
                      name="dynamic_form_item"
                      ref={formRef}
                      {...formItemLayoutWithOutLabel}
                      onFinish={onFinish}
                      initialValues={{ userQuestion: question, travelPlan: plan, topic: topic }}
                      style={{ maxWidth: 600 }}
                    >
                       <Form.Item
                           name='userQuestion'
                           label='问题'
                          rules={[
                            {
                              required: true,
                              message: "请输入问题",
                            },
                          ]}
                        >
                          <Input.TextArea maxLength={500} autoSize={{ minRows: 2, maxRows: 50 }} />
                        </Form.Item>
                      <Form.List
                        name="names"
                        // rules={[
                        //   {
                        //     validator: async (_, names) => {
                        //       if (!names || names.length < 1) {
                        //         return Promise.reject(new Error('最少要有一个问法'));
                        //       }
                        //     },
                        //   },
                        // ]}
                      >
                        {(fields, { add, remove }, { errors }) => (
                          <>
                            {fields.map((field, index) => (
                              <Form.Item
                                {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                                label={index === 0 ? '更多问法' : ''}
                                required={false}
                                key={field.key}
                              >
                                <Form.Item
                                  {...field}
                                  validateTrigger={['onChange', 'onBlur']}
                                  rules={[
                                    {
                                      required: true,
                                      whitespace: true,
                                      message: "请输入问题",
                                    },
                                  ]}
                                  noStyle
                                >
                                  <Input.TextArea placeholder="输入新的问法" maxLength={500} autoSize={{ minRows: 2, maxRows: 50 }} />
                                </Form.Item>
                                {fields.length > 0 ? (
                                  <MinusCircleOutlined
                                    className="dynamic-delete-button"
                                    onClick={() => remove(field.name)}
                                  />
                                ) : null}
                              </Form.Item>
                            ))}
                            <Form.Item>
                              <Button
                                type="dashed"
                                onClick={() => add()}
                                style={{ width: '60%' }}
                                icon={<PlusOutlined />}
                              >
                                增加问法
                              </Button>
                              <Form.ErrorList errors={errors} />
                            </Form.Item>
                          </>
                        )}
                      </Form.List>
                      <Form.Item
                          name="topic"
                          label='主题'
                          rules={[{
                            required: true}]}
                        >
                          <Checkbox.Group style={{ width: '100%' }} onChange={onChange} defaultValue={topic}>
                            <Row>
                              <Col span={8}>
                                <Checkbox value="经典游澳">经典游澳</Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox value="深度游澳">深度游澳</Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox value="葡韵风情">葡韵风情</Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox value="历史文旅">历史文旅</Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox value="文艺游澳">文艺游澳</Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox value="美食之旅">美食之旅</Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox value="亲子旅游">亲子旅游</Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox value="眺望澳景">眺望澳景</Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox value="活动观光">活动观光</Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox value="出片绝景">出片绝景</Checkbox>
                              </Col>
                              <Col span={8}>
                                <Checkbox value="赌场风云">赌场风云</Checkbox>
                              </Col>
                            </Row>
                          </Checkbox.Group>
                        </Form.Item>
                      <Form.Item
                          name="duration"
                          label="时长(天)"
                          rules={[{
                            required: true,
                          }]}
                        >
                          <Select
                            defaultValue={duration}
                            style={{ width: 120, display: 'flex'}}
                            onChange={handleChange}
                            options={[
                              { value: 1, label: '1' },
                              { value: 2, label: '2' },
                              { value: 3, label: '3' }
                            ]}
                          />
                        </Form.Item>
                      <Form.Item
                          name="travelPlan"
                          label="行程"
                          rules={[{
                            required: true,
                            message: '  请输入行程计划'
                          }]}
                        >
                          <Input.TextArea  showCount maxLength={2000} autoSize={{ minRows: 2, maxRows: 50 }} />
                        </Form.Item>
                      <Form.Item>
                        <Button type="primary" htmlType="submit">
                          提交
                        </Button>
                      </Form.Item>
                    </Form>
                  </div>
                </Spin>
            )
          }
        </Content>
        <Footer style={{ textAlign: 'center' }}>
          ©{new Date().getFullYear()} Created by 优创译科技有限公司
        </Footer>
      </Layout>
    </Layout>
  );
};

export default App;
